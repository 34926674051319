import React, { useState } from 'react'

import styles from "./style.module.css";

import { Star } from "lucide-react";
import { FaArrowTrendDown } from "react-icons/fa6";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const LineChart = ({ data, year, setYear }) => {
  const [chartSelect, setChartSelect] = useState("This Week");
  const [requestSelect, setRequestSelect] = useState("This Week");
  const [showChartSelect, setShowChartSelect] = useState(false);
  const [showRequestOptions, setShowRequestOptions] = useState(false);
  const startYear = 2022;
  const endYear = new Date().getFullYear();
  const years = Array.from({ length: endYear - startYear + 1 }, (_, i) => startYear + i);

  const chartSelectOptions = [
    "Today",
    "3 days Ago",
    "This Week",
    "This Month",
    "1 Year Ago",
    "Enter Custom Date",
  ];

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        backgroundColor: "rgba(0, 150, 0, 1)",
        titleColor: "white",
        bodyColor: "white",
        callbacks: {
          label: function (context) {
            return `${context.raw.toLocaleString()} orders`;
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          color: "#A0A0A0",
        },
        border: {
          color: "#EAEAEA",
        },
      },
      y: {
        grid: {
          color: "#EAEAEA",
          borderDash: [5, 5],
          lineWidth: 1.5,
        },
        ticks: {
          callback: function (value) {
            return `${value} orders`;
          },
          color: "#A0A0A0",
          maxTicksLimit: 5,
        },
        border: {
          display: false,
        },
      },
      barDatasetSpacing: 40,
    },
  };

  console.log(data);
  

  return (
    <div className={styles.line__chart}>
      <div className={styles.line__chart_header}>
        <p className={styles.type}>Top Vendors</p>
        <select className={styles.select} onChange={(e) => setYear(e.target.value)}>
          <option value="">Select Year</option>
          <option value="">Default</option>
          {years.map((year) => (
            <option key={year} value={year}>
              {year}
            </option>
          ))}
        </select>
      </div>
      <div className={styles.line__chart_chart}>
        <Bar data={data} options={options} />
      </div>
    </div>
  );
}

export default LineChart