import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import axios from "axios";
import Navigation from "../../widgets/Navigation";
import OverviewMobileNav from "../../widgets/OverviewMobileNav";
import ActiveOrders from "../../components/dashboard/orderManagement/ActiveOrders";
import RecentOrdersTable from "../../components/dashboard/overview/RecentOrdersTable";
import RecentTransactionsCard from "../../components/dashboard/overview/RecentTransactionsCard";
import StatsCardContainer from "../../components/dashboard/overview/StatsCardContainer";
import LineChart from "./LineChart";
import {
  getOrders,
  getOrdersAll,
  getActiveOrders,
} from "../../redux/actions/OrdersAction";
import {
  getRevenues,
  getTransactions,
  
} from "../../redux/actions/TransactionsActions";
import { getUsers, getVendors } from "../../redux/actions/OtherActions";

const Overview = () => {
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery({ query: "(min-width: 1200px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 1200px)" });
    const [year, setYear] = useState("")
  const { orders, gettingOrders } = useSelector((state) => state.orders);
  const [activeOrders, setActiveOrders] = useState([]);
  const [recentOrders, setRecentOrders] = useState([]);
  const [chartData, setChartData] = useState(null);

  const colors = [
    "rgb(255, 146, 174)",
    "rgb(172, 173, 175)",
    "rgb(76, 111, 255)",
  ];

  useEffect(() => {
    dispatch(getRevenues());
    dispatch(getUsers());
    dispatch(getTransactions());
    dispatch(getOrders());
  }, [dispatch]);

  useEffect(() => {
    if (orders?.data?.orders) {
      const active = orders.data.orders.filter((x) =>
        [
          "ORDER_ASSIGNED",
          "ORDER_ACCEPTED",
          "ORDER_REJECTED",
          "ORDER_PICKED_UP",
          "ORDER_CONFIRMED",
        ].includes(x.orderStatus)
      );
      setActiveOrders(active);
    }
  }, [orders]);

  useEffect(() => {
    if (orders?.data?.orders) {
      const sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);
      const recent = orders.data.orders.filter(
        (order) => new Date(order.updatedAt) >= sevenDaysAgo
      );
      setRecentOrders(recent);
    }
  }, [orders]);

  const fetchChartData = async () => {
    try {
      const res = await axios.get(
        `https://dadofoods.herokuapp.com/api/v1/admin/orders/dashboard-chart${
          year ? "?year=" + year : ""
        }`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );
      const rawData = res.data.data;
      // console.log(rawData.data);

      const labels = rawData.monthlyOrderCounts?.map((entry) => {
        const date = new Date(entry.date); // Convert to Date object
        return date.toLocaleString("en-US", {
          month: "short",
          year: "2-digit",
        });
        // Outputs: "Jan-24"
      });
       const businessNames = Object.values(
         rawData.top3BusinessesCounts || {}
       ).reduce((acc, businessData) => {
         Object.keys(businessData).forEach((name) => acc.add(name));
         return acc;
       }, new Set());

      const datasets = [
        {
          label: "Total Orders",
          backgroundColor: "rgb(4, 154, 44)",
          borderColor: "rgb(4, 154, 44)",
          borderWidth: 1,
          data: rawData.monthlyOrderCounts?.map((entry) => entry.count),
          borderRadius: 15,
          // barPercentage: 1.2,
          // categoryPercentage: .7,
          barThickness: 10,
        },
        ...Array.from(businessNames).map((business, index) => ({
          label: business,
          backgroundColor: `${colors[index]}`,
          borderColor: `${colors[index]}`,
          borderWidth: 1,
          borderRadius: 15,
          // barPercentage: 1.2,
          // categoryPercentage: .7,
          barThickness: 10,

          data: labels.map(
            (date, i) =>
              rawData.top3BusinessesCounts[
                rawData.monthlyOrderCounts[i].date
              ]?.[business] || 0
          ),
        })),
      ];

      setChartData({ labels, datasets });
    } catch (error) {
      console.error("Error fetching chart data", error);
    }
  };

  useEffect(() => {
    fetchChartData();
  }, [year]);

  return (
    <>
      <Navigation />
      {isDesktop ? (
        <main className="overViewContainer">
          <StatsCardContainer />
          <section className="ordersSection">
            <div style={{ width: "100%" }}>
              {chartData && <LineChart data={chartData} year={year} setYear={setYear} />}
            </div>
            <RecentTransactionsCard />
          </section>
          <section className="ordersSection">
            <RecentOrdersTable />
            <ActiveOrders
              activeOrders={activeOrders}
              gettingActiveOrders={gettingOrders}
              title="Active Orders"
            />
          </section>
        </main>
      ) : (
        <>
          <OverviewMobileNav />
          <main className="overViewContainer">
            <StatsCardContainer />
            <ActiveOrders
              activeOrders={recentOrders}
              gettingActiveOrders={gettingOrders}
              title="Recent Orders"
            />
          </main>
        </>
      )}
    </>
  );
};

export default Overview;
